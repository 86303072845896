import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ana-sayfa-aciklama-alani',
  templateUrl: './ana-sayfa-aciklama-alani.component.html',
  styleUrls: ['./ana-sayfa-aciklama-alani.component.css']
})
export class AnaSayfaAciklamaAlaniComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
