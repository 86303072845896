import {Directive, ElementRef, EventEmitter, Output} from '@angular/core';

@Directive({
    selector: '[appElementTakip]'
})
export class ElementTakipDirective {

    @Output()
    degisiklik = new EventEmitter<any>();

    constructor(public el: ElementRef) {

        //console.log(el);

    }

    ngOnInit(){

        this.degisiklik.emit({a: "değişiklik",q:1});

        let loadImage = (e) => {

            this.degisiklik.emit({a: "değişiklik",e:e} );

            // var img = e.target,
            //     width = img.clientWidth,
            //     height = img.clientHeight;
            //
            // img.removeEventListener('load', loadImage, false);
            // alert("Image loaded: width: " + width + " height: " + height);
        };

        let observer = new MutationObserver((mutations) => {

            this.degisiklik.emit({ a: "değişiklik", b:mutations } );

            mutations.forEach(function (mutation: any) {
                switch (mutation.type) {
                    case 'childList':
                        Array.prototype.forEach.call(mutation.target.children, function (child) {
                            child.addEventListener('load', loadImage, false);
                        });

                        break;
                    default:
                }
            });


        });

        observer.observe( this.el.nativeElement , {
            attributes: true,
            childList: true,
            characterData: true,
            subtree: true
        });


    }

}
