import { Component, OnInit } from '@angular/core';
import {DataServisiService} from '../../data-servisi.service';

@Component({
  selector: 'app-blog-listesi',
  templateUrl: './blog-listesi.component.html',
  styleUrls: ['./blog-listesi.component.css']
})
export class BlogListesiComponent implements OnInit {

  constructor(public servis: DataServisiService) { }

  ngOnInit() {
  }

}
