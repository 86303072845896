import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';

@Pipe({
    name: 'safe'
})
export class SafePipe implements PipeTransform {

    constructor(protected sanitizer: DomSanitizer) {}

    public transform(value: any, type: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
        switch (type) {
            case 'html': return this.sanitizer.bypassSecurityTrustHtml(value);
            case 'style': return this.sanitizer.bypassSecurityTrustStyle(value);
            case 'script': return this.sanitizer.bypassSecurityTrustScript(value);
            case 'url': return this.sanitizer.bypassSecurityTrustUrl(value);
            case 'resourceUrl': return this.sanitizer.bypassSecurityTrustResourceUrl(value);
            default: throw new Error(`Invalid safe type specified: ${type}`);
        }
    }
}

@Injectable({
    providedIn: 'root'
})
export class DataServisiService {
    public gorunen_ekran = 'header';
    public aktif_slide: any = 0;
    public orjinal_blogs: any = [];
    public orjinal_blogs_index: any = {};
    public orjinal_data: any = [];
    public orjinal_data_slug_index: any = {};
    public orjinal_data_cat_index: any = {};
    public anasayfa_slider: any = [];
    public orjinal_data_son_eklenenler: any = [];
    public orjinal_data_anasayfada_gorunenler: any = [];
    public gelen_datalar: any = [];
    tamamlandi = false;
    async init() {
        const temel_data: any = await this.get('/data' );
        this.orjinal_data = temel_data.projects;
        this.orjinal_blogs = temel_data.blogs;
        this.anasayfa_slider = temel_data.anasayfa_slider;
        this.anasayfa_slider = this.anasayfa_slider.sort(( x , y ) => {
            if (x.order > y.order) return  1;
            if (x.order < y.order) return -1;
            return 0;
        });
        this.orjinal_blogs = this.orjinal_blogs.sort(( x , y ) => {
            if (x.tarih > y.tarih) return  1;
            if (x.tarih < y.tarih) return -1;
            return 0;
        });
        this.orjinal_blogs_index = {};
        for (let i = 0; i < this.orjinal_blogs.length; i++) {
            this.orjinal_blogs_index[ this.orjinal_blogs[i].slug ] = this.orjinal_blogs[i];
        }
        console.log('orjinal_blogs_index' , this.orjinal_blogs_index );
        // console.log( 'sort1' , this.orjinal_data );
        this.orjinal_data = this.orjinal_data.sort( ( x , y ) => {
            if (x.tarih === undefined && y.tarih === undefined) return 0;
            if (x.tarih === undefined && y.tarih !== undefined) return 1;
            if (x.tarih !== undefined && y.tarih === undefined) return -1;
            const xtarih = new Date(x.tarih).getTime();
            const ytarih = new Date(y.tarih).getTime();
            // // console.log( xtarih , ytarih );
            if (xtarih > ytarih) return -1;
            if (xtarih < ytarih) return 1;
            return 0;
        });
        // console.log( 'sort2' , this.orjinal_data );

        this.orjinal_data = this.orjinal_data.map((item) => {
            // console.log(item.isim, item.thumbnail );
            if (item.body === '<p>.</p>') item.body = '';
            if (item.aciklama === '.') item.aciklama = '';
            item.slug = this.url_slug( item.isim , {} );
            item.thumbnail_data = {};
            item.taglar = (item.tags || '').split( ',' );
            item.taglar = item.taglar.map( ( item ) => {
                return item.trim();
            });
            item.taglar = item.taglar.filter( ( item ) => {
                return item !== '';
            });
            if (item.thumbnail === undefined || item.thumbnail === '') {
                if (item.content_type === 'resim' ) {
                    const resimler = item.media;
                    if (resimler !== undefined && resimler.length > 0) {
                        item.thumbnail = resimler[0].dosya;
                        item.thumbnail_data = resimler[0];
                    }
                }
                if (item.content_type === 'video' ) {
                    const videolar = item.videolar;
                    if (videolar !== undefined && videolar.length > 0) {
                        item.thumbnail = videolar[0].dosya;
                        item.thumbnail_data = videolar[0];
                    }
                }
            } else {

                if (item.content_type === 'resim' ) {
                    const resimler = item.media;
                    if (resimler !== undefined && resimler.length > 0) {
                        const c = resimler.filter(( _item ) => {
                            return _item.dosya === item.thumbnail;
                        });
                        item.thumbnail_data = c[0];
                    }
                }
                if (item.content_type === 'video' ) {
                    const videolar = item.videolar;
                    if (videolar !== undefined && videolar.length > 0) {
                        const c = videolar.filter(( _item ) => {
                            return _item.dosya === item.thumbnail;
                        });
                        item.thumbnail_data = c[0];
                    }
                }

            }
            this.orjinal_data_slug_index[ item.slug ] = item;
            this.orjinal_data_cat_index[ this.url_slug( item.category, {})  ] = this.orjinal_data_cat_index[ this.url_slug(item.category, {})  ] || [];
            this.orjinal_data_cat_index[ this.url_slug( item.category, {})  ].push( item );
            return item;
        });
        // console.log( 'sort3' , this.orjinal_data );
        this.orjinal_data = this.orjinal_data.filter((item) => {
            return item.thumbnail !== undefined && item.thumbnail !== '';
        });
        this.orjinal_data_son_eklenenler = this.orjinal_data.filter((item) => {
            return item.anasayfa_gorunurluk_durumu !== true;
        });

        // console.log( 'this.gelen_datalar'  , this.orjinal_data );
        // console.log( 'this.orjinal_data_slug_index'  , this.orjinal_data_slug_index );
        // console.log( 'this.orjinal_data_cat_index'  , this.orjinal_data_cat_index );
        this.tamamlandi = true;
    }
    capitalizeIt(str) {
        if (str && typeof(str) === 'string') {
            str = str.split(' ');
            for (let i = 0, x = str.length; i < x; i++) {
                if (str[i]) {
                    str[i] = str[i][0].toUpperCase() + str[i].substr(1);
                }
            }
            return str.join(' ');
        } else {
            return str;
        }
    }
    bekle() {
        return new Promise(async (resolve) => {
            while (this.tamamlandi === false) {
                await this.sleep(250);
            }
            resolve();
        });
    }
    sleep(ms) {
        return new Promise(async (resolve) => {
            setTimeout ( () => {
                resolve();
            } , ms ) ;
        });
    }
    constructor(public http: HttpClient) {
        this.init();
    }
    getId(url) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        const match = url.match(regExp);
        if (match && match[2].length === 11) {
            return match[2];
        } else {
            return 'error';
        }
    }


    get(link , sayi = 0) {

        return new Promise((resolve) => {

            this.http.get(link).subscribe(data => {
                resolve(data);
            }, error => {
                ++sayi;
                if (sayi > 3) {
                    resolve(undefined);
                } else {
                    setTimeout(async () => {
                        await this.get(link, sayi);
                    }, 200);
                }
            });

        });

    }

    post(link , data) {
        return new Promise((resolve) => {
            this.http.post(link, data).subscribe(data => {
                resolve(data)
            }, error => {
                resolve(undefined);
            });
        });
    }
    url_slug(s, opt) {
        s = String(s);
        opt = Object(opt);

        var defaults = {
            'delimiter': '-',
            'limit': undefined,
            'lowercase': true,
            'replacements': {},
            'transliterate':true
        };

        // Merge options
        for (var k in defaults) {
            if (!opt.hasOwnProperty(k)) {
                opt[k] = defaults[k];
            }
        }

        var char_map = {
            // Latin
            'À': 'A', 'Á': 'A', 'Â': 'A', 'Ã': 'A', 'Ä': 'A', 'Å': 'A', 'Æ': 'AE',
            'È': 'E', 'É': 'E', 'Ê': 'E', 'Ë': 'E', 'Ì': 'I', 'Í': 'I', 'Î': 'I', 'Ï': 'I',
            'Ð': 'D', 'Ñ': 'N', 'Ò': 'O', 'Ô': 'O', 'Õ': 'O', 'Ő': 'O',
            'Ø': 'O', 'Ù': 'U', 'Ú': 'U', 'Û': 'U', 'Ű': 'U', 'Ý': 'Y', 'Þ': 'TH',
            'ß': 'ss',
            'à': 'a', 'á': 'a', 'â': 'a', 'ã': 'a', 'ä': 'a', 'å': 'a', 'æ': 'ae',
            'è': 'e', 'é': 'e', 'ê': 'e', 'ë': 'e', 'ì': 'i', 'í': 'i', 'î': 'i', 'ï': 'i',
            'ð': 'd', 'ñ': 'n', 'ò': 'o', 'ô': 'o', 'õ': 'o',  'ő': 'o',
            'ø': 'o', 'ù': 'u', 'ú': 'u', 'û': 'u',  'ű': 'u', 'ý': 'y', 'þ': 'th',
            'ÿ': 'y',

            // Latin symbols
            '©': '(c)',

            // Greek
            'Α': 'A', 'Β': 'B', 'Γ': 'G', 'Δ': 'D', 'Ε': 'E', 'Ζ': 'Z', 'Η': 'H', 'Θ': '8',
            'Ι': 'I', 'Κ': 'K', 'Λ': 'L', 'Μ': 'M', 'Ν': 'N', 'Ξ': '3', 'Ο': 'O', 'Π': 'P',
            'Ρ': 'R', 'Σ': 'S', 'Τ': 'T', 'Υ': 'Y', 'Φ': 'F', 'Χ': 'X', 'Ψ': 'PS', 'Ω': 'W',
            'Ά': 'A', 'Έ': 'E', 'Ί': 'I', 'Ό': 'O', 'Ύ': 'Y', 'Ή': 'H', 'Ώ': 'W', 'Ϊ': 'I',
            'Ϋ': 'Y',
            'α': 'a', 'β': 'b', 'γ': 'g', 'δ': 'd', 'ε': 'e', 'ζ': 'z', 'η': 'h', 'θ': '8',
            'ι': 'i', 'κ': 'k', 'λ': 'l', 'μ': 'm', 'ν': 'n', 'ξ': '3', 'ο': 'o', 'π': 'p',
            'ρ': 'r', 'σ': 's', 'τ': 't', 'υ': 'y', 'φ': 'f', 'χ': 'x', 'ψ': 'ps', 'ω': 'w',
            'ά': 'a', 'έ': 'e', 'ί': 'i', 'ό': 'o', 'ύ': 'y', 'ή': 'h', 'ώ': 'w', 'ς': 's',
            'ϊ': 'i', 'ΰ': 'y', 'ϋ': 'y', 'ΐ': 'i',

            // Turkish
            'Ş': 'S', 'İ': 'I', 'Ç': 'C', 'Ü': 'U', 'Ö': 'O', 'Ğ': 'G',
            'ş': 's', 'ı': 'i', 'ç': 'c', 'ü': 'u', 'ö': 'o', 'ğ': 'g',

            // Russian
            'А': 'A', 'Б': 'B', 'В': 'V', 'Г': 'G', 'Д': 'D', 'Е': 'E', 'Ё': 'Yo', 'Ж': 'Zh',
            'З': 'Z', 'И': 'I', 'Й': 'J', 'К': 'K', 'Л': 'L', 'М': 'M', 'Н': 'N', 'О': 'O',
            'П': 'P', 'Р': 'R', 'С': 'S', 'Т': 'T', 'У': 'U', 'Ф': 'F', 'Х': 'H', 'Ц': 'C',
            'Ч': 'Ch', 'Ш': 'Sh', 'Щ': 'Sh', 'Ъ': '', 'Ы': 'Y', 'Ь': '', 'Э': 'E', 'Ю': 'Yu',
            'Я': 'Ya',
            'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd', 'е': 'e', 'ё': 'yo', 'ж': 'zh',
            'з': 'z', 'и': 'i', 'й': 'j', 'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n', 'о': 'o',
            'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u', 'ф': 'f', 'х': 'h', 'ц': 'c',
            'ч': 'ch', 'ш': 'sh', 'щ': 'sh', 'ъ': '', 'ы': 'y', 'ь': '', 'э': 'e', 'ю': 'yu',
            'я': 'ya',

            // Ukrainian
            'Є': 'Ye', 'І': 'I', 'Ї': 'Yi', 'Ґ': 'G',
            'є': 'ye', 'і': 'i', 'ї': 'yi', 'ґ': 'g',

            // Czech
            'Ď': 'D', 'Ě': 'E', 'Ň': 'N', 'Ř': 'R',  'Ť': 'T', 'Ů': 'U',
            'Ž': 'Z',
            'ď': 'd', 'ě': 'e', 'ň': 'n', 'ř': 'r', 'ť': 't', 'ů': 'u',
            'ž': 'z',

            // Polish
            'Ą': 'A', 'Ć': 'C', 'Ę': 'e', 'Ł': 'L', 'Ń': 'N', 'Ó': 'o', 'Ś': 'S', 'Ź': 'Z',
            'Ż': 'Z',
            'ą': 'a', 'ć': 'c', 'ę': 'e', 'ł': 'l', 'ń': 'n', 'ó': 'o', 'ś': 's', 'ź': 'z',
            'ż': 'z',

            // Latvian
            'Ā': 'A', 'Č': 'C', 'Ē': 'E', 'Ģ': 'G', 'Ī': 'i', 'Ķ': 'k', 'Ļ': 'L', 'Ņ': 'N',
            'Š': 'S', 'Ū': 'u',
            'ā': 'a', 'č': 'c', 'ē': 'e', 'ģ': 'g', 'ī': 'i', 'ķ': 'k', 'ļ': 'l', 'ņ': 'n',
            'š': 's', 'ū': 'u',
        };

        // Make custom replacements
        for (var k in opt.replacements) {
            s = s.replace(RegExp(k, 'g'), opt.replacements[k]);
        }

        // Transliterate characters to ASCII
        if (opt.transliterate) {
            for (var k in char_map) {
                s = s.replace(RegExp(k, 'g'), char_map[k]);
            }
        }

        // Replace non-alphanumeric characters with our delimiter
        var alnum = RegExp('[^a-z0-9]+', 'ig');
        s = s.replace(alnum, opt.delimiter);

        // Remove duplicate delimiters
        s = s.replace(RegExp('[' + opt.delimiter + ']{2,}', 'g'), opt.delimiter);

        // Truncate slug to max. characters
        s = s.substring(0, opt.limit);

        // Remove delimiter from ends
        s = s.replace(RegExp('(^' + opt.delimiter + '|' + opt.delimiter + '$)', 'g'), '');

        return opt.lowercase ? s.toLowerCase() : s;
    }



    getgetir(link: string, cb: any) {
        this.get2(link).subscribe(data => {
            cb(data);
        }, error => {
            cb({});
        });
    }

    postgetir(link: string, data: any, cb: any) {
        // console.log( 'postgetir' , link , data);
        this.post2(link, data ).subscribe(data => {
            cb(data);
        }, error => {
            cb({});
        });
    }

    post2(link: string, data: any) {
        return this.http.post(link, data);
    }

    get2(link: string) {
        return this.http.get(link);
    }

}
