import { Component, OnInit } from '@angular/core';
import {DataServisiService} from '../../data-servisi.service';

declare var $: any;
declare var fitty: any;

@Component({
  selector: 'app-header',
  templateUrl: 'header.html',
  styles: [`
  `]
})
export class HeaderComponent implements OnInit {

    menu1int = 0;
    menu2int = 0;
    menu1timer;
    menu2timer;

    menu_kapandi = false;

    menu1_itemler = [
       {
         text: 'Since 2000',
         link: '',
       },
       {
         text: 'Museums',
         link: 'museums',
       },
       {
         text: 'Architecture',
         link: 'architecture',
       },
       {
         text: 'Design',
         link: 'design',
       },
       {
         text: 'Interiors',
         link: 'interiors',
       },
   ];

   menu2_itemler = [
       {
         text: 'Exhibition',
         link: '',
       },
       {
         text: 'Corporate',
         link: 'exhibition-corporate',
       },
       {
         text: 'Pavillions',
         link: 'exhibition-pavillons',
       },
       {
         text: 'Medical',
         link: 'exhibition-medial',
       },
       {
         text: 'Events',
         link: 'exhibition-events',
       },
   ];

  constructor(public servis: DataServisiService) { }

  ngOnInit() {
    this.menu1timer = setInterval((item) => {
        ++this.menu1int;
        // ++this.menu2int;
    }, 5000);
    // this.menu2timer = setInterval((item) => {
    // }, 3000);

      $(document).ready(function () {
          // $("#sidebar").mCustomScrollbar({
          //   theme: "minimal"
          // });

          $('#dismiss, .overlay').on('click', function () {
              // hide sidebar
              $('#sidebar').removeClass('active');
              // hide overlay
              $('.overlay').removeClass('active');
          });

          $('#sidebarCollapse').on('click', function () {
              // open sidebar
              $('#sidebar').addClass('active');
              // fade in the overlay
              $('.overlay').addClass('active');
              // $('.collapse.in').toggleClass('in');
              // $('a[aria-expanded=true]').attr('aria-expanded', 'false');
          });
      });

  }

}
