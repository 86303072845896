import { Component, OnInit } from '@angular/core';
import {DataServisiService} from '../../data-servisi.service';
import {ActivatedRoute} from '@angular/router';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-blog-goster',
  templateUrl: './blog-goster.component.html',
  styleUrls: ['./blog-goster.component.css']
})
export class BlogGosterComponent implements OnInit {

  aktif_slug = '';
  constructor(public servis: DataServisiService, public route: ActivatedRoute, public titleService: Title) { }

  ngOnInit() {

      this.route.params.subscribe(async (params) => {
          await this.servis.bekle();
          this.aktif_slug = undefined;
          if (params.slug !== undefined) {
              console.log( 'this.aktif_slug1' , params , this.aktif_slug , this.servis.orjinal_blogs_index );
              if (this.servis.orjinal_blogs_index[params.slug] !== undefined) {
                  console.log( 'this.aktif_slug2' , params , this.aktif_slug , this.servis.orjinal_blogs_index );
                  this.aktif_slug = params.slug;
                  // this.titleService.setTitle( this.servis.orjinal_data_slug_index[params.slug].isim + ' | Sda Exhibition' );
              }
          }
          console.log( 'this.aktif_slug3' , params , this.aktif_slug , this.servis.orjinal_blogs_index );
      } ) ;

  }

}
