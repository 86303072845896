import {Component, HostListener, OnInit} from '@angular/core';
import {DataServisiService} from '../../data-servisi.service';
import {ActivatedRoute} from '@angular/router';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-proje-goster',
  templateUrl: './proje-goster.component.html',
  styleUrls: ['./proje-goster.component.css']
})
export class ProjeGosterComponent implements OnInit {

  aktif_slug = undefined;
  constructor(public servis: DataServisiService, public route: ActivatedRoute, public titleService: Title) { }

    yy;

    yenileme_talebi_timer;

    @HostListener('window:resize', ['$event'])
    onResize(event) {

        console.log( 'window:resize' , event.target.innerWidth , event.target.innerHeight );

        const height = window.innerHeight;

        const doc = document.documentElement;
        const left = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
        const top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);

        // this.aktif_scroll_y      = top;
        // this.aktif_window_height = height;

        this.yenileme_talebi(undefined);

    }

    yenileme_talebi(event) {

        try {
            const listeleme_sonuc_alani: any = document.querySelector('.timeline-grid2' );
            clearTimeout(this.yenileme_talebi_timer);
            this.yenileme_talebi_timer = setTimeout(() => {
                this.rebuild_masonry();
            }, 200 );
        } catch (e) { }

    }
    rebuild_masonry() {

        try {
            console.log( 'rebuild_masonry' );

            const listeleme_sonuc_alani: any = document.querySelector('.timeline-grid2' );

            const w = window,
                d = document,
                e = d.documentElement,
                g = d.getElementsByTagName('body')[0],
                x = w.innerWidth || e.clientWidth || g.clientWidth,
                y = w.innerHeight || e.clientHeight || g.clientHeight;

            // let sutun_sayisi = 5;
            //
            // if (x < 1300 && x > 1100){
            //     sutun_sayisi = 4;
            // }else if (x <= 1100 && x > 900){
            //     sutun_sayisi = 3;
            // }else if (x <= 900 && x > 600){
            //     sutun_sayisi = 2;
            // }else if (x <= 600){
            //     sutun_sayisi = 1;
            // }
            let sutun_sayisi = 2;

            if (x <= 1367 && x > 1024) {
                sutun_sayisi = 2;
            } else if (x <= 1024 && x > 640) {
                sutun_sayisi = 2;
            } else if (x <= 640) {
                sutun_sayisi = 1;
            }


            const alanlar = {};
            for (let i = 0; i < sutun_sayisi; i++) {
                alanlar[i] = {
                    sayi: 0,
                    height: 0,
                };
            }

            const tamami = document.querySelectorAll('.timeline-grid2 .grid-item');

            const en_kucuk_sirayi_bul = () => {

                let min_olan = 0;

                let keys = Object.keys(alanlar);
                for (let i = 0; i < keys.length; i++) {
                    if (alanlar[ min_olan ].height > alanlar[ keys[i] ].height ){
                        min_olan = i;
                    }
                }

                return min_olan;

            };

            const en_buyuk_sirayi_bul = () => {

                let min_olan = 0;

                const keys = Object.keys(alanlar);
                for (let i = 0; i < keys.length; i++) {
                    if (alanlar[ min_olan ].height < alanlar[ keys[i] ].height ){
                        min_olan = i;
                    }
                }

                return min_olan;

            };

            const vertical_margin   = 20;
            const horizontal_margin = 30;

            listeleme_sonuc_alani.style.position = 'relative';

            for (let i = 0; i < tamami.length; i++) {

                const aktif_el: any = tamami[i];

                const hedef_alan = en_kucuk_sirayi_bul();

                aktif_el.style.width = (((listeleme_sonuc_alani.offsetWidth / sutun_sayisi)) ) + 'px' ;

                const aktif_height = aktif_el.offsetHeight;

                aktif_el.style.display = 'block';


                aktif_el.style.aktif_height = aktif_height + 'px';

                const _left  =  (((((listeleme_sonuc_alani.offsetWidth / sutun_sayisi)) - (horizontal_margin)) * hedef_alan) + (hedef_alan * horizontal_margin)) + 'px';
                const _top   =  ((alanlar[ hedef_alan ].height) + (vertical_margin * alanlar[ hedef_alan ].sayi)) + 'px';

                aktif_el.style.left = '0px';
                aktif_el.style.top  = '0px';


                const transform_style = 'translateX(' + _left + ') translateY(' + _top + ')';

                aktif_el.style.webkitTransform = transform_style;
                aktif_el.style.MozTransform = transform_style;
                aktif_el.style.msTransform = transform_style;
                aktif_el.style.OTransform = transform_style;
                aktif_el.style.transform = transform_style;

                aktif_el.style.position = 'absolute';


                alanlar[ hedef_alan ].sayi++;
                alanlar[ hedef_alan ].height = alanlar[ hedef_alan ].height + aktif_height;

            }

            const en_buyuk =  en_buyuk_sirayi_bul() ;

            listeleme_sonuc_alani.style.height = (alanlar[en_buyuk].height + ( (alanlar[en_buyuk].sayi + 1) * vertical_margin )) + 'px' ;
            listeleme_sonuc_alani.style.opacity = 1;

        } catch (e) {

        }

    }

    y() {

        this.yy = setTimeout(() => {
            try {
                this.yenileme_talebi(undefined);
                this.y();
            } catch ( e ) {
            }
        }, 1000 );

    }

    ngOnInit() {
      this.y();
      this.route.params.subscribe(async (params) => {
        await this.servis.bekle();
          this.aktif_slug = undefined;
          if (params.slug !== undefined) {
              await this.servis.bekle();
              if (this.servis.orjinal_data_slug_index[params.slug] !== undefined) {
                  this.aktif_slug = params.slug;

                  this.titleService.setTitle( this.servis.orjinal_data_slug_index[params.slug].isim + ' | Sda Exhibition' );

                  this.yenileme_talebi(undefined);
                  setTimeout(() => {
                      this.yenileme_talebi(undefined);
                  }, 1000 );

              }
              this.yenileme_talebi(undefined);
              setTimeout(() => {
                  this.yenileme_talebi(undefined);
              }, 1000 );
          }
          this.yenileme_talebi(undefined);
          setTimeout(() => {
              this.yenileme_talebi(undefined);
          }, 1000 );
          console.log( 'this.aktif_slug' , params , this.aktif_slug );
      } ) ;

  }

}
