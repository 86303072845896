import {Component, OnInit, ViewChild} from '@angular/core';
import {DataServisiService} from '../../data-servisi.service';
import {OwlCarousel} from 'ngx-owl-carousel';

@Component({
  selector: 'app-ana-sayfa-slider',
  templateUrl: './ana-sayfa-slider.component.html',
  styleUrls: ['./ana-sayfa-slider.component.css']
})
export class AnaSayfaSliderComponent implements OnInit {
  @ViewChild('owlElement') owlElement: OwlCarousel
  @ViewChild('scr1') scr1;
  tmp_son_deger = 0;
  timer1;

  constructor(public servis: DataServisiService) { }
  onchange1(event) {
    console.log( 'onchange1' , event );
  }
  onchange2 = (event) => {
    this.tmp_son_deger = event.page.index;
    this.servis.aktif_slide = this.tmp_son_deger;
    try {
        this.owlElement.trigger('stop.owl.autoplay');
        this.owlElement.trigger('play.owl.autoplay');
    } catch (e) {}
  }
  s() {
    let sonuc = this.servis.aktif_slide + 2;
    if (sonuc > this.servis.anasayfa_slider.length - 1) sonuc = 1;
    return sonuc;
  }
  o() {
    let sonuc = this.servis.aktif_slide ;
    if (sonuc < 1) sonuc = this.servis.anasayfa_slider.length;
    return sonuc;
  }
  alta_git() {
    console.log(this.scr1.nativeElement);
    let konum2 = this.scr1.nativeElement.offsetTop;
    console.log( konum2 );
    window.scroll({
        left: 0,
        top: konum2 - 120,
        behavior: 'smooth'
    });
  }
  async ngOnInit() {
    await this.servis.bekle();
  }
}
