import {Component, OnInit} from '@angular/core';
import {DataServisiService} from './data-servisi.service';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`,
  styles: []
})
export class AppComponent implements OnInit {

  constructor(public servis: DataServisiService) {
  }

  async ngOnInit() {
    await this.servis.bekle();
  }
}
