import {BrowserModule, Title} from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { OwlModule } from 'ngx-owl-carousel';
import { HeaderComponent } from './Alanlar/header/header.component';
import { FooterComponent } from './Alanlar/footer/footer.component';
import { HeaderFooterComponent } from './Layouts/header-footer/header-footer.component';
import { ProjeListesiComponent } from './Alanlar/proje-listesi/proje-listesi.component';
import { SafePipe } from './data-servisi.service';
import { NgxMasonryModule } from 'ngx-masonry';
import { AnaSayfaSliderComponent } from './Alanlar/ana-sayfa-slider/ana-sayfa-slider.component';
import { AnaSayfaAciklamaAlaniComponent } from './Alanlar/ana-sayfa-aciklama-alani/ana-sayfa-aciklama-alani.component';
import { HttpClientModule } from '@angular/common/http';
import { ElementTakipDirective } from './element-takip.directive';
import { ListelemeComponent } from './Sayfalar/listeleme/listeleme.component';
import { ProjeGosterComponent } from './Sayfalar/proje-goster/proje-goster.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { IletisimComponent } from './Sayfalar/iletisim/iletisim.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { BlogListesiComponent } from './Sayfalar/blog-listesi/blog-listesi.component';
import { BlogGosterComponent } from './Sayfalar/blog-goster/blog-goster.component';
import { AnaSayfaAciklamaComponent } from './Alanlar/ana-sayfa-aciklama/ana-sayfa-aciklama.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    HeaderFooterComponent,
    ProjeListesiComponent,
    SafePipe,
    AnaSayfaSliderComponent,
    AnaSayfaAciklamaAlaniComponent,
    ElementTakipDirective,
    ListelemeComponent,
    ProjeGosterComponent,
    IletisimComponent,
    BlogListesiComponent,
    BlogGosterComponent,
    AnaSayfaAciklamaComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'my-app'}),
    RouterModule.forRoot([
      {
          path: '',
          component: HeaderFooterComponent,
          children: [
            {
                path: '',
                component: HomeComponent,
                pathMatch: 'full'
            } ,
            {
                path: 'list/:cat',
                component: ListelemeComponent,
                pathMatch: 'full'
            } ,
            {
                path: 'project/:slug',
                component: ProjeGosterComponent,
                pathMatch: 'full'
            } ,
            {
                path: 'contact',
                component: IletisimComponent,
                pathMatch: 'full'
            } ,
            {
                path: 'blogs',
                component: BlogListesiComponent,
                pathMatch: 'full'
            } ,
            {
                path: 'blog/:slug',
                component: BlogGosterComponent,
                pathMatch: 'full'
            } ,
        ],
      },
    ]),
    NgxMasonryModule,
    TransferHttpCacheModule,
    OwlModule,
    HttpClientModule,
    InfiniteScrollModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
      Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
