import { Component, OnInit } from '@angular/core';
import {DataServisiService} from '../../data-servisi.service';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-listeleme',
  templateUrl: './listeleme.component.html',
  styleUrls: ['./listeleme.component.css']
})
export class ListelemeComponent implements OnInit {

  aktif_cat = undefined;
  constructor(public servis: DataServisiService, public route: ActivatedRoute, public titleService: Title) { }

  d(t) {
      if (t === undefined) return '';
      return t.replace( '-'  , ' / ' );
  }

  ngOnInit() {
      this.route.params.subscribe(async (params) => {
          await this.servis.bekle();
          this.aktif_cat = undefined;
          if (params.cat !== undefined) {
              await this.servis.bekle();
              if (this.servis.orjinal_data_cat_index [params.cat] !== undefined) {
                  this.aktif_cat = params.cat;
                  this.titleService.setTitle( this.servis.capitalizeIt(params.cat) + ' | Sda Exhibition' );
              } else {
                  this.titleService.setTitle( 'Not Found | Sda Exhibition' );
              }
          }
          console.log( 'this.aktif_cat' , params , this.aktif_cat );
      } ) ;
  }

}
