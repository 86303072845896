import { Component, OnInit } from '@angular/core';
import {DataServisiService} from '../../data-servisi.service';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-iletisim',
  templateUrl: './iletisim.component.html',
  styleUrls: ['./iletisim.component.css']
})
export class IletisimComponent implements OnInit {

    aktif_ulke = 0;
    iletisim_diller = [
        {
            phone: '+971 (0) 4 3941564',
            mail: 'info@sdaexhibition.com',
            address: 'Sapphire Tower 206, Al ittihad Road, <br>Deira / Dubai',
            ulke: 'UAE',
            sehir: 'Dubai',
            resim: '19326879_1000x699x72.jpg',
        },
        {
            phone: '+7 (495) 957-57-31',
            mail: 'info@sdaexhibition.com',
            address: 'Russian federation, 115172, c. Moscow, <br>16/1 Goncharnaya street',
            ulke: 'Russia',
            sehir: 'Moscow',
            resim: '4382_1000x699x72.jpg',
        },
        {
            phone: 'Opening Soon in Frankfurt',
            mail: 'info@sdaexhibition.com',
            address: 'Opening Soon <br> in Frankfurt',
            ulke: 'Germany',
            sehir: 'Frankfurt',
            resim: 'frankfurt_1000x699x72.jpg',
        },
    ];

    hatavar2 = false;
    hatamesaji2 = '';
    gonderiliyor = false;
    gonderim_sonuclandi = false;
    gonderim_sonuc = '';
    gonderim_basarili = false;
    aktif_captcha: any = undefined;
    iletisim_datasi: any = {
        ad_soyad : '',
        eposta : '',
        konu : '',
        mesaj : '',
    };
    constructor(public servis: DataServisiService, public titleService: Title) { }
    async gonder() {
        const gidecek_data = this.iletisim_datasi;
        this.hatavar2 = false;
        this.hatamesaji2 = '';
        if (this.aktif_captcha.girilen_data === undefined || this.aktif_captcha.girilen_data === '') {
            this.hatavar2    = true;
            this.hatamesaji2 =  'Security Code is Empty';
            return;
        }
        if (gidecek_data.ad_soyad === undefined || gidecek_data.ad_soyad === '') {
            this.hatavar2    = true;
            this.hatamesaji2 = 'Name Surname is Empty' ;
            return;
        }
        if (gidecek_data.eposta === undefined || gidecek_data.eposta === '') {
            this.hatavar2    = true;
            this.hatamesaji2 = 'E-Mail is Empty';
            return;
        }
        if (gidecek_data.konu === undefined || gidecek_data.konu === '') {
            this.hatavar2    = true;
            this.hatamesaji2 = 'Subject is Empty';
            return;
        }
        if (gidecek_data.mesaj === undefined || gidecek_data.mesaj === '') {
            this.hatavar2    = true;
            this.hatamesaji2 = 'Your Message is Empty';
            return;
        }
        const aktif_captcha_clone = JSON.parse(JSON.stringify(this.aktif_captcha));
        delete aktif_captcha_clone.data;
        gidecek_data.captcha = aktif_captcha_clone;
        console.log( 'gidecek_data' , gidecek_data );
        this.gonderiliyor = true;
        const sonuc: any = await this.servis.post('/mail' , {
            ne: gidecek_data
        });
        this.gonderim_sonuclandi = true;
        if (sonuc.ok === 1 || sonuc.ok === '1') {
            this.gonderim_basarili = true;
            this.iletisim_datasi = {
                ad_soyad : '',
                eposta : '',
                konu : '',
                mesaj : '',
            };
        } else {
            this.gonderim_basarili = false;
        }
        this.aktif_captcha = await this.servis.get( '/captcha?a=' + Math.random() );
        this.gonderim_sonuc = sonuc.sebep;

        this.gonderiliyor = false;

        console.log( 'sonuc' , sonuc );
    }
    async captcha_yenile() {
        console.log( 'captcha_yenile' );
        this.aktif_captcha = await this.servis.get( '/captcha?a=' + Math.random() );
        console.log( 'captcha_yenile2' );
    }
    async yenile() {
        this.aktif_captcha = await this.servis.get( '/captcha?a=' + Math.random() );
    }
    ngOnInit() {
        // this.titleService.setTitle( 'İletişim - Özdemir Porselen' );
        this.yenile();

        this.titleService.setTitle( 'Keep in Touch - Contact | Sda Exhibition' );

    }
    e(metin) {
        if (metin === undefined) return '';
        return metin.replace(/\n/g, '<br>');
    }

}
